
import { Component, Vue, } from 'vue-property-decorator'
import ActBox from '@/components/ActBox/ActBox.vue'
import { mapGetters, mapState, } from 'vuex'
import { ActivityInterface, } from '@/store/types/Activities/ActivitiesGeneral'
import OverlayBackground from "@/components/OverlayBackground/Index.vue"

@Component({
  components: {
    ActBox,OverlayBackground
  },
  computed: {
    ...mapState('AuthModule', ['AuthState',]),
    ...mapState('BusinessModule', ['BusinessState',]),
    ...mapGetters('ActivitiesModule', ['CompanyActivities',]),
    ...mapGetters('ActivitiesModule', { metadata: 'getMetaData', }),
    ...mapGetters('BusinessModule', ['getBusinessClient',]),
  },
})
export default class PlanComponent extends Vue {
  [x: string]: any
  private activitysByFase: ActivityInterface[] = []
  private showAct = false
  private expandPhase = 1
  private total_activities = 0
  private total_activities_dones = 0
  private percentGraffic = 0
  private metadata: any

  private status_selected: any = []
  private showFilter = false
  private search = ''
  private status_filter = [
    'pending',
    'progress',
    'hackmetrix_review',
    'company_review',
    'company_approved',
    'upload_documentation',
    'notify_documentation',
    'done',
  ]
  private loading = false;

  beforeMount () {
    const status = localStorage.getItem(
      'activityStatus' + this.BusinessState.businessSelected.id + this.AuthState.role
    )
    const search = localStorage.getItem(
      'activitySearch' + this.BusinessState.businessSelected.id + this.AuthState.role
    )

    if (status && JSON.parse(status).length > 0) {
      const status_array = JSON.parse(status)
      this.status_selected.push(...status_array)
    }

    if (search) {
      this.search = search
    }
  }

  mounted () {
    this.getCompanyActivities()
  }

  private resetVars () {
    this.search = ''
    this.status_selected = []
    this.$mixpanel.track('Borrar filtros')
    this.getCompanyActivities()
    localStorage.removeItem(
      'activityStatus' + this.BusinessState.businessSelected.id + this.AuthState.role
    )
    localStorage.removeItem(
      'activitySearch' + this.BusinessState.businessSelected.id + this.AuthState.role
    )
  }

  private elementPhase (selected: any) {
    history.pushState({}, 'clientPlan', this.$route.path + '#' + selected.phase)

    selected.open = !selected.open
    localStorage.setItem(
      'phase' + selected.phase + this.BusinessState.businessSelected.id + this.AuthState.role,
      selected.open
    )
  }

  private getLabel (activity: any): string {
    const totalActivities = activity.activities.length
    // const firstActivity = activity.activities[0].name.substring(
    //   0,
    //   activity.activities[0].name.indexOf('.')
    // )
    // const lastActivity = activity.activities[totalActivities - 1].name.substring(
    //   0,
    //   activity.activities[totalActivities - 1].name.indexOf('.')
    // )
    return `Fase ${activity.phase} (${activity.done}/${totalActivities})`
  }

  private getCompanyActivities () {
    this.total_activities = 0
    this.total_activities_dones = 0

    localStorage.removeItem(
      'activityStatus' + this.BusinessState.businessSelected.id + this.AuthState.role
    )
    localStorage.removeItem(
      'activitySearch' + this.BusinessState.businessSelected.id + this.AuthState.role
    )

    if (this.search && this.search !== '') {
      localStorage.setItem(
        'activitySearch' + this.BusinessState.businessSelected.id + this.AuthState.role,
        this.search
      )
    }

    if (JSON.stringify(this.status_selected).length > 0) {
      localStorage.setItem(
        'activityStatus' + this.BusinessState.businessSelected.id + this.AuthState.role,
        JSON.stringify(this.status_selected)
      )
    }

    const body = {
      name: localStorage.getItem(
        'activitySearch' + this.BusinessState.businessSelected.id + this.AuthState.role
      )
        ? localStorage.getItem(
          'activitySearch' + this.BusinessState.businessSelected.id + this.AuthState.role
        )
        : this.search,
      status: this.status_selected,
    }

    if (this.status_selected.length != 0 || this.search != '') {
      this.$mixpanel.track('Filtros actividades', {
        Filtros: this.status_selected,
        Buscar: this.search,
      })
    }
    this.showFilter = false

    this.loading = true
    this.$store.dispatch('ActivitiesModule/getCompanyActivities', body).then((response) => {
      response.forEach((element: any) => {
        if (
          localStorage.getItem(
            'phase' + element.phase + this.BusinessState.businessSelected.id + this.AuthState.role
          )
        ) {
          const isOpenLS = localStorage.getItem(
            'phase' + element.phase + this.BusinessState.businessSelected.id + this.AuthState.role
          )

          if (isOpenLS === 'true') {
            element.open = true
          } else if (isOpenLS === 'false') {
            element.open = false
          }
        } else {
          element.open = true
        }

        this.total_activities = this.total_activities + element.activities.length

        element.done = 0

        element.activities.forEach((data: any) => {
          if (data.status === 'done') {
            element.done = element.done + 1
          }
        })
        this.total_activities_dones = this.total_activities_dones + element.done
      })

      this.activitysByFase = response

      this.percentGraffic = (this.metadata.done * 100) / this.metadata.total

      this.loading = false
    })
  }
}
